import joi from "joi";

const modulusLength = joi.valid(2048, 4096);
const namedCurve = joi.valid("P-256", "P-384", "P-521");
const aesLength = joi.valid(128, 256);
const hashFunction = joi.valid("SHA-256", "SHA-384", "SHA-512");

export const keyParamSchema = joi
  .object({
    use: joi.valid("sign", "encrypt").required(),
    type: joi.valid("symmetric", "asymmetric").required(),
    name: joi.valid("HMAC", "ECDSA", "AES-GCM", "RSA-OAEP").required(),
  })
  .concat(
    joi.object({}).when(".name", {
      switch: [
        {
          is: "HMAC",
          then: joi.object({
            use: "sign",
            type: "symmetric",
            hash: hashFunction.required(),
          }),
        },
        {
          is: "ECDSA",
          then: joi.object({
            use: "sign",
            type: "asymmetric",
            namedCurve: namedCurve.required(),
          }),
        },
        {
          is: "AES-GCM",
          then: joi.object({
            use: "encrypt",
            type: "symmetric",
            length: aesLength.required(),
          }),
        },
        {
          is: "RSA-OAEP",
          then: joi.object({
            use: "encrypt",
            type: "asymmetric",
            hash: hashFunction.required(),
            modulusLength: modulusLength.required(),
            publicExponent: joi
              .valid(new Uint8Array([0x01, 0x00, 0x01]))
              .default(new Uint8Array([0x01, 0x00, 0x01])),
          }),
        },
      ],
    })
  )
  .strict();

export interface HMACParams {
  use: "sign";
  type: "symmetric";
  name: "HMAC";
  hash: "SHA-256" | "SHA-384" | "SHA-512";
}

export interface ECDSAParams {
  use: "sign";
  type: "asymmetric";
  name: "ECDSA";
  namedCurve: "P-256" | "P-384" | "P-521";
}

export interface AESParams {
  use: "encrypt";
  type: "symmetric";
  name: "AES-GCM";
  length: 128 | 192 | 256;
}

export interface RSAParams {
  use: "encrypt";
  type: "asymmetric";
  name: "RSA-OAEP";
  modulusLength: 2048 | 4096;
  hash: "SHA-256" | "SHA-384" | "SHA-512";
  publicExponent: Uint8Array;
}

export type KeyParams = HMACParams | ECDSAParams | AESParams | RSAParams;
export type CryptoKeyType<Params> = Params extends HMACParams | AESParams
  ? CryptoKey
  : Params extends ECDSAParams | RSAParams
  ? CryptoKeyPair
  : never;

export function generateKey(params: RSAParams): Promise<CryptoKeyPair>;
export function generateKey(params: HMACParams): Promise<CryptoKey>;
export function generateKey(params: AESParams): Promise<CryptoKey>;
export function generateKey(params: ECDSAParams): Promise<CryptoKeyPair>;

export function generateKey(params: KeyParams) {
  if (params.name === "HMAC") {
    return window.crypto.subtle.generateKey(
      { name: params.name, hash: params.hash },
      true,
      ["sign", "verify"]
    );
  } else if (params.name === "AES-GCM") {
    return window.crypto.subtle.generateKey(
      { name: params.name, length: params.length },
      true,
      ["encrypt", "decrypt"]
    );
  } else if (params.name === "ECDSA") {
    return window.crypto.subtle.generateKey(
      { name: params.name, namedCurve: params.namedCurve },
      true,
      ["sign", "verify"]
    );
  } else if (params.name === "RSA-OAEP") {
    return window.crypto.subtle.generateKey(
      {
        name: params.name,
        modulusLength: params.modulusLength,
        hash: params.hash,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      },
      true,
      ["encrypt", "decrypt"]
    );
  }
}

export function validateKeyParams(
  params: Partial<KeyParams>
): KeyParams | null {
  const result = keyParamSchema.validate(params);
  if (result.error) {
    return null;
  }
  return result.value as KeyParams;
}
