import {
  MenuItem,
  Select,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { KeyParams, keyParamSchema } from "./crypto.ts";

interface KeyParamsForm {
  initialKeyParams: Partial<KeyParams> & Extract<KeyParams, "use" | "type">;
  includeKeyId: boolean;
  onKeyParamsChange: (keyParams: KeyParams) => void;
  onIncludeKeyIdChange: (inclueKeyId: boolean) => void;
}

export default function KeyParamsForm(props: KeyParamsForm) {
  const initialValidated = keyParamSchema.validate(props.initialKeyParams);

  const [params, setParams] = useState<Partial<KeyParams>>(
    initialValidated.value as Partial<KeyParams>
  );
  const [isValid, setIsValid] = useState<boolean>(
    initialValidated.error == null
  );

  const { includeKeyId, onIncludeKeyIdChange } = props;

  useEffect(() => {
    if (isValid) {
      props.onKeyParamsChange(params as KeyParams);
    } else {
      props.onKeyParamsChange(null);
    }
  }, [isValid, params]);

  const updateParams = (newParams: Partial<KeyParams>) => {
    const updated = { ...params, ...newParams };

    if (updated.use && updated.type) {
      if (updated.use === "sign") {
        updated.name = updated.type === "asymmetric" ? "ECDSA" : "HMAC";
      } else {
        updated.name = updated.type === "asymmetric" ? "RSA-OAEP" : "AES-GCM";
      }
    } else {
      delete updated.name;
    }

    if (updated.name === "HMAC") {
      updated.hash ??= "SHA-256";
      delete updated.length;
      delete updated.namedCurve;
      delete updated.modulusLength;
      delete updated.publicExponent;
    } else if (updated.name === "ECDSA") {
      updated.namedCurve ??= "P-256";
      delete updated.length;
      delete updated.hash;
      delete updated.modulusLength;
      delete updated.publicExponent;
    } else if (updated.name === "AES-GCM") {
      updated.length ??= 256;
      delete updated.namedCurve;
      delete updated.hash;
      delete updated.modulusLength;
      delete updated.publicExponent;
    } else if (updated.name === "RSA-OAEP") {
      updated.modulusLength ??= 2048;
      updated.hash ??= "SHA-256";
      updated.publicExponent ??= new Uint8Array([0x01, 0x00, 0x01]);
      delete updated.length;
      delete updated.namedCurve;
    }

    const validated = keyParamSchema.validate(updated);
    setIsValid(validated.error == null);
    console.log(validated.error);
    setParams(validated.value);
  };

  return (
    <Grid container spacing={2}>
      <Param
        format="toggle"
        label="Use"
        choices={["sign", "encrypt"]}
        value={params.use}
        onChange={(use) => updateParams({ use })}
      />

      <Param
        format="toggle"
        label="Type"
        choices={["asymmetric", "symmetric"]}
        value={params.type}
        onChange={(type) => updateParams({ type })}
      />

      {params.name != null && (
        <Param
          format="toggle"
          label="Algorithm"
          choices={[params.name]}
          value={params.name}
          onChange={(name) => updateParams({ name })}
        />
      )}

      {params.name === "ECDSA" && (
        <>
          <Param
            label="Elliptic curve"
            value={params.namedCurve}
            onChange={(namedCurve) => updateParams({ namedCurve })}
            choices={["P-256", "P-384", "P-521"]}
          />
        </>
      )}

      {params.name === "HMAC" && (
        <>
          <Param
            label="Hash function"
            choices={["SHA-256", "SHA-384", "SHA-512"]}
            value={params.hash}
            onChange={(hash) => updateParams({ hash })}
          />
        </>
      )}

      {params.name === "AES-GCM" && (
        <>
          <Param
            label="Key length"
            choices={[128, 256]}
            value={params.length}
            onChange={(length) => updateParams({ length })}
          />
        </>
      )}

      {params.name === "RSA-OAEP" && (
        <>
          <Param
            label="Modulus length"
            choices={[2048, 4096]}
            value={params.modulusLength}
            onChange={(modulusLength) => updateParams({ modulusLength })}
          />
          <Param
            label="Hash function"
            choices={["SHA-256", "SHA-384", "SHA-512"]}
            value={params.hash}
            onChange={(hash) => updateParams({ hash })}
          />
        </>
      )}

      <Grid item xs={3}>
        <Typography variant="body1" component="label" sx={{ display: 'block' }}>
          Include Key ID?
        </Typography>
        <Typography variant="caption">
          Uses <a href="https://datatracker.ietf.org/doc/html/rfc7638" rel="external">JWT.Thumbprint</a>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Switch
          checked={includeKeyId}
          onChange={(ev) => onIncludeKeyIdChange(ev.target.checked)}
        />
      </Grid>
    </Grid>
  );
}

function Param<T extends string | number>(props: {
  format?: "toggle" | "select";
  label: string;
  value: T;
  choices: T[];
  onChange: (newVal: T) => void;
}) {
  return (
    <>
      <Grid item xs={3}>
        <Typography variant="body1" component="label">
          {props.label}:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {(props.format ?? "select") === "select" ? (
          <Select
            value={props.value}
            onChange={(ev) =>
              ev.target.value && props.onChange(ev.target.value as T)
            }
          >
            {props.choices.map((choice) => (
              <MenuItem key={choice} value={choice}>
                {choice}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <ToggleButtonGroup
            value={props.value}
            exclusive
            onChange={(_ev, newValue) =>
              newValue != null && props.onChange(newValue as T)
            }
          >
            {props.choices.map((choice) => (
              <ToggleButton key={choice} value={choice}>
                {choice}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      </Grid>
    </>
  );
}
