import * as React from "react";
import { useState } from "react";
import { encode } from "base64-arraybuffer";
import base64url from "./base64url.ts";

interface KeypairProps {
  kid?: string;
  format: "jwk" | "pem";
  keypair: CryptoKeyPair;
  type: "public" | "private";
}

interface KeyProps {
  kid?: string;
  format: "jwk" | "b64" | "b64url";
  key_: CryptoKey;
}

type KeyFormatterProps = KeypairProps | KeyProps;

function pemEncode(buf: ArrayBuffer, header: string): string {
  const asb64 = encode(buf).replace(/(.{64})/g, "$1\n");
  return `-----BEGIN ${header}-----\n${asb64}\n-----END ${header}-----`;
}

function CodeBlock(props: { children: any }) {
  return (
    <pre>
      <code>{props.children}</code>
    </pre>
  );
}

export default function KeyFormatter(props: KeyFormatterProps) {
  const { format } = props;

  const [formatted, setFormatted] = useState("");

  let key: CryptoKey;
  let header: string;
  let exportMethod: "spki" | "pkcs8" | "raw";
  if (isKeyPair(props)) {
    key =
      props.type === "public"
        ? props.keypair.publicKey
        : props.keypair.privateKey;
    header = props.type === "public" ? "PUBLIC KEY" : "PRIVATE KEY";
    exportMethod = props.type === "public" ? "spki" : "pkcs8";
  } else {
    key = props.key_;
    exportMethod = "raw";
  }

  if (format === "jwk") {
    window.crypto.subtle.exportKey("jwk", key).then((exported) => {
      if (props.kid) {
        exported["kid"] = props.kid;
      }
      setFormatted(JSON.stringify(exported, Object.keys(exported).sort(), 4));
    });
  } else if (exportMethod === "raw") {
    window.crypto.subtle.exportKey("raw", key).then((exported) => {
      switch (format) {
        case "b64":
          setFormatted(encode(exported));
          break;
        case "b64url":
          setFormatted(base64url(exported));
          break;
        default:
          throw new Error(`Unrecognized format: ${format}`);
      }
    });
  } else {
    window.crypto.subtle.exportKey(exportMethod, key).then((exported) => {
      setFormatted(pemEncode(exported, header));
    });
  }

  return <CodeBlock>{formatted}</CodeBlock>;
}

function isKeyPair(props: KeyFormatterProps): props is KeypairProps {
  return (props as KeypairProps).type != null;
}
