import * as React from "react";
import { useState } from "react";
import { render } from "react-dom";
import KeyDisplay from "./KeyDisplay.tsx";
import "./style.css";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { generateKey, type KeyParams } from "./crypto.ts";
import thumbprint from "./thumbprint.ts";
import KeyParamsForm from "./KeyParamsForm.tsx";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
}));

function App() {
  const [includeKeyId, setIncludeKeyId] = useState<boolean>(true);
  const [keyParams, setKeyParams] = useState<KeyParams | null>(null);
  const [frozenKeyParams, setFrozenKeyParams] = useState<KeyParams | null>(
    null
  );
  const [keyId, setKeyId] = useState<string | null>(null);
  const [key, setKey] = useState<CryptoKey | CryptoKeyPair | null>(null);

  const doGenerateKey = () => {
    if (keyParams == null) return;
    const currentKeyParams = keyParams;
    generateKey(keyParams).then((generatedKey: CryptoKey | CryptoKeyPair) => {
      setKey(generatedKey);
      setFrozenKeyParams(currentKeyParams);
      return thumbprint(generatedKey).then((keyId) => {
        console.log(`got thumbprint ${keyId}`);
        setKeyId(keyId);
      });
    });
  };

  return (
    <Container maxWidth="md" sx={{ pt: 4 }}>
      <Stack spacing={4}>
        <Item>
          <Stack spacing={1}>
            <Typography variant="h4" component="h1">
              JSON Web Key Generator
            </Typography>
            <Typography variant="body1">
              Generate{" "}
              <a
                href="https://datatracker.ietf.org/doc/html/rfc7517"
                rel="external"
              >
                JSON Web Keys
              </a>{" "}
              in the browser using the{" "}
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API"
                rel="external"
              >
                Web Crypto API
              </a>
              , and export them as JWKs in JSON format, PEM-encoded SPKI (for
              public keys), PEM-encoded PKCS#8 (for private keys), or raw
              Base64/Base64URL (for symmetric keys).
            </Typography>
            <Typography variant="body1">
              All keys are generated completely client-side, and this page
              includes no third-party assets or scripts.
              <br />
              Check the Network tab for proof.
            </Typography>
          </Stack>
        </Item>

        <Item>
          <Stack spacing={2}>
            <Typography variant="h6" component="h2">
              Key Parameters
            </Typography>

            <KeyParamsForm
              initialKeyParams={{}}
              onKeyParamsChange={setKeyParams}
              includeKeyId={includeKeyId}
              onIncludeKeyIdChange={setIncludeKeyId}
            />

            <Button
              variant="contained"
              disabled={keyParams == null}
              onClick={doGenerateKey}
            >
              Generate {keyParams?.type === "symmetric" ? "Key" : "Keypair"}
            </Button>
          </Stack>
        </Item>

        {key != null && (
          <Item>
            <Typography variant="h6" component="h2" gutterBottom>
              Generated {frozenKeyParams?.name} key
            </Typography>
            <KeyDisplay kid={includeKeyId ? keyId : null} key_={key} />
          </Item>
        )}
      </Stack>
    </Container>
  );
}

render(<App />, document.getElementById("root"));
