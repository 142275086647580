import { encode } from "base64-arraybuffer";

function getKeyFields(jwk: JsonWebKey): Array<keyof JsonWebKey> {
  switch (jwk.kty) {
    case "RSA":
      return ["e", "kty", "n"];
    case "EC":
      return ["crv", "kty", "x", "y"];
    case "oct":
      return ["k", "kty"];
    default:
      throw new Error("Cannot thumbprint unknown JWK type");
  }
}

function base64url(buf: ArrayBuffer): string {
  return encode(buf).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

export default async function thumbprint(
  key: JsonWebKey | CryptoKey | CryptoKeyPair
): Promise<string> {
  let jwk: JsonWebKey;
  if ((key as JsonWebKey).kty != null) {
    jwk = key as JsonWebKey;
  } else if ((key as CryptoKeyPair).publicKey != null) {
    jwk = await crypto.subtle.exportKey(
      "jwk",
      (key as CryptoKeyPair).publicKey
    );
  } else {
    jwk = await crypto.subtle.exportKey("jwk", key as CryptoKey);
  }

  const fieldOrder = getKeyFields(jwk);
  const json = JSON.stringify(jwk, fieldOrder);
  const jsonBytes = new TextEncoder().encode(json);
  const digest = await crypto.subtle.digest("SHA-256", jsonBytes);
  return base64url(digest);
}
