import * as React from "react";
import KeyFormatter from "./KeyFormatter.tsx";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Stack } from "@mui/material";

interface KeyDisplayProps {
  kid?: string;
  key_?: CryptoKeyPair | CryptoKey;
}

export default function KeyDisplay(props: KeyDisplayProps) {
  if (props.key_ == null) {
    return <></>;
  } else if (props.key_.hasOwnProperty("publicKey")) {
    return (
      <KeypairDisplay kid={props.kid} keypair={props.key_ as CryptoKeyPair} />
    );
  } else if (props.key_ instanceof CryptoKey) {
    return <SingleKeyDisplay kid={props.kid} key_={props.key_} />;
  }
}

interface KeypairDisplayProps {
  kid?: string;
  keypair: CryptoKeyPair;
}

function KeypairDisplay(props: KeypairDisplayProps) {
  const { kid, keypair } = props;

  const [keyType, setKeyType] = useState<"public" | "private">("public");
  const [keyFormat, setKeyFormat] = useState<"jwk" | "pem">("jwk");

  return (
    <Stack spacing={1}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={keyType}
          onChange={(_ev, newKeyType) => setKeyType(newKeyType)}
          aria-label="Choose which half of the keypair to view"
        >
          <Tab label="Public" value="public" />
          <Tab label="Private" value="private" />
        </Tabs>
      </Box>
      <ToggleButtonGroup
        value={keyFormat}
        exclusive
        onChange={(_ev, newFormat) => setKeyFormat(newFormat ?? keyFormat)}
        aria-label="Key format"
      >
        <ToggleButton value="jwk">JWK</ToggleButton>
        <ToggleButton value="pem">PEM</ToggleButton>
      </ToggleButtonGroup>
      <KeyFormatter
        keypair={keypair}
        kid={kid}
        type={keyType}
        format={keyFormat}
      />
    </Stack>
  );
}

interface SingleKeyDisplayProps {
  kid?: string;
  key_: CryptoKey;
}

function SingleKeyDisplay(props: SingleKeyDisplayProps) {
  const { kid, key_ } = props;

  const [keyFormat, setKeyFormat] = useState<"jwk" | "pem">("jwk");

  return (
    <Stack spacing={1}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={0} aria-label="Viewing the shared key">
          <Tab label="Shared" value={0} />
        </Tabs>
      </Box>
      <ToggleButtonGroup
        value={keyFormat}
        exclusive
        onChange={(_ev, newFormat) => setKeyFormat(newFormat ?? keyFormat)}
        aria-label="Key format"
      >
        <ToggleButton value="jwk">JWK</ToggleButton>
        <ToggleButton value="b64">Base64</ToggleButton>
        <ToggleButton value="b64url">Base64URL</ToggleButton>
      </ToggleButtonGroup>
      <KeyFormatter kid={kid} key_={key_} format={keyFormat} />
    </Stack>
  );
}
